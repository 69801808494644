
window.showSdkCmp = function () {
    if (window.webkit) {
        window.webkit.messageHandlers.requestConsent.postMessage("");
    } else {
        Android.requestConsent();
    }
}

// https://quantcastsupport.zendesk.com/hc/en-us/articles/360048696153-User-FAQ-for-Quantcast-Choice-on-TCF-v2-0-
window.showWebCmp = function () {
    window.__tcfapi('displayConsentUi', 2, function () {
    });
}
